<template>
  <header class="navbar">
    <div class="brand" @click="navigateTo('StudentDashboard')">
      <img :src="brandImage" alt="Brand Icon" class="brand-icon" />
    </div>

    <nav class="menu" v-if="utils.isSignedIn() && utils.isAdmin()">
      <div class="menu-item" @click="navigateTo('AdminStatistics')">
        {{ $t('Statistics') }}
      </div>
      <div class="menu-item" @click="navigateTo('StudentSearch')">
        {{ $t('Student_Search') }}
      </div>
      <div class="menu-item" @click="navigateTo('SendHelperSignUpEmail')">
        {{ $t('Send_Helper_Sign_Up_Email') }}
      </div>
      <div class="menu-item" @click="navigateTo('EventList')">
        {{ $t('Events') }}
      </div>
    </nav>

    <nav class="menu" v-else>
      <div class="menu-item" @click="navigateTo('StudentDashboard')">
        {{ $t('Dashboard') }}
      </div>
      <div class="menu-item" @click="navigateTo('StudentStatistics')">
        {{ $t('Statistics') }}
      </div>
      <div class="menu-item" @click="navigateTo('StudentSearch')">
        {{ $t('Student_Search') }}
      </div>
      <div class="menu-item" @click="navigateTo('DroppedStudentList')">
        {{ $t('Dropped_Students') }}
      </div>
      <div class="menu-item" @click="navigateTo('StudentList')">
        {{ $t('Attendance') }}
      </div>
      <div class="menu-item" @click="navigateTo('EventList')">
        {{ $t('Event') }}
      </div>
    </nav>

    <div class="spacer"></div>

    <el-button
      circle
      style="margin-right: 20px"
      @click="handleClickNotificationBtn"
    >
      <el-badge
        :value="unreadCount"
        class="item"
        :offset="[5, -5]"
        :show-zero="false"
      >
        <el-icon>
          <Bell />
        </el-icon>
      </el-badge>
    </el-button>

    <el-dropdown
      class="profile-menu"
      trigger="click"
      size="large"
      popper-class="profile-menu-popper"
    >
      <span class="el-dropdown-link">
        {{ currentProfile?.firstName }}
        <el-icon class="el-icon--right"><arrow-down /></el-icon>
      </span>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item @click="navigateTo('Settings')">
            {{ $t('Settings') }}
          </el-dropdown-item>
          <el-dropdown-item @click="signOut" divided>
            {{ $t('Sign_Out') }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>

    <el-button class="collapse-menu" @click="openMenuDrawer = true">
      <img src="@/assets/icons/hamburger.svg" style="width: 30px" />
    </el-button>
  </header>

  <el-drawer v-model="openMenuDrawer" direction="rtl" style="min-width: 200px">
    <div class="drawer-menu">
      <div class="drawer-menu-item" @click="navigateTo('StudentDashboard')">
        {{ $t('Dashboard') }}
      </div>
      <div class="drawer-menu-item" @click="navigateTo('StudentStatistics')">
        {{ $t('Statistics') }}
      </div>
      <div class="drawer-menu-item" @click="navigateTo('StudentSearch')">
        {{ $t('Student_Search') }}
      </div>
      <div class="drawer-menu-item" @click="navigateTo('DroppedStudentList')">
        {{ $t('Dropped_Students') }}
      </div>
      <div class="drawer-menu-item" @click="navigateTo('StudentList')">
        {{ $t('Attendance') }}
      </div>
      <div class="drawer-menu-item" @click="navigateTo('EventList')">
        {{ $t('Event') }}
      </div>
      <el-divider></el-divider>
      <div class="drawer-menu-item" @click="navigateTo('Settings')">
        {{ $t('Settings') }}
      </div>
      <div class="drawer-menu-item" @click="signOut">
        {{ $t('Sign_Out') }}
      </div>
    </div>
  </el-drawer>

  <el-drawer
    v-model="openNotificationDrawer"
    direction="rtl"
    style="min-width: 350px"
    title="Notifications"
    class="notification-drawer"
    @closed="handleNotificationDrawerClosed"
  >
    <div
      v-infinite-scroll="loadMoreNotifications"
      infinite-scroll-disabled="isLoading"
      infinite-scroll-distance="10"
    >
      <div v-if="notifications.length">
        <div
          v-for="notification in notifications"
          :key="notification.id"
          class="notification-item"
          :class="{ 'unread-notification': !notification.isRead }"
        >
          <div class="notification-message" v-html="notification.message"></div>
          <div class="notification-time">
            {{ displayTimeAgo(notification.createdAt) }}
          </div>
        </div>
      </div>
      <div v-else-if="!isLoading" class="no-notifications">
        Your inbox is clear. No notifications at the moment.
      </div>
      <div v-if="isLoading" class="loading-notifications">
        Loading more notifications...
      </div>
    </div>
  </el-drawer>
</template>

<script>
import webSocketService from '@/helper/webSocketService'
import { DateTime } from 'luxon'
import NotificationService from '@/services/NotificationService'
import brandImage from '@/assets/logo.png'
import { USER_LOCAL_STORAGE_KEY } from '@/helper/constants'
import utils from '@/helper/utils'

export default {
  data() {
    return {
      notifications: [],
      currentPage: 1,
      totalPages: 1,
      isLoading: false,
      unreadCount: 0,
      openMenuDrawer: false,
      openNotificationDrawer: false,
      brandImage: brandImage,
      utils,
    }
  },
  computed: {
    currentProfile() {
      return this.$store.state.auth.profile
    },
  },
  methods: {
    // stripHtml(html) {
    //   const tempDiv = document.createElement('div')
    //   tempDiv.innerHTML = html

    //   return tempDiv.innerText || ''
    // },
    navigateTo(routeName) {
      this.openMenuDrawer = false
      this.$router.push({ name: routeName })
    },
    signOut() {
      localStorage.removeItem(USER_LOCAL_STORAGE_KEY)
      this.$store.dispatch('auth/clearProfile')

      // Send event to Flutter WebView
      utils.sendEventToFlutter('SIGN_OUT')

      this.$router.push('/login')
    },
    displayTimeAgo(timestamp) {
      const now = DateTime.local()
      const time = DateTime.fromISO(timestamp)
      const diff = now
        .diff(time, ['minutes', 'hours', 'days', 'weeks'])
        .toObject()

      if (diff.weeks >= 1) {
        const weeks = Math.floor(diff.weeks)
        return `${weeks} week${weeks > 1 ? 's' : ''} ago`
      } else if (diff.days >= 1) {
        const days = Math.floor(diff.days)
        return `${days} day${days > 1 ? 's' : ''} ago`
      } else if (diff.hours >= 1) {
        const hours = Math.floor(diff.hours)
        return `${hours} hour${hours > 1 ? 's' : ''} ago`
      } else {
        const minutes = Math.floor(diff.minutes)
        return `${minutes} minute${minutes > 1 ? 's' : ''} ago`
      }
    },
    async handleClickNotificationBtn() {
      this.openNotificationDrawer = true
      await this.loadNotifications()
    },
    async loadNotifications(page = 1) {
      this.isLoading = true
      try {
        const resp = await NotificationService.getNotifications(page)
        if (page === 1) {
          this.notifications = resp.data.notifications
        } else {
          this.notifications = [
            ...this.notifications,
            ...resp.data.notifications,
          ]
        }
        this.currentPage = resp.data.currentPage
        this.totalPages = resp.data.totalPages
      } catch (e) {
        utils.handleHttpError(e, this)
      } finally {
        this.isLoading = false
      }
    },
    async loadMoreNotifications() {
      if (this.currentPage < this.totalPages && !this.isLoading) {
        await this.loadNotifications(this.currentPage + 1)
      }
    },
    async getUnreadCount() {
      try {
        const resp = await NotificationService.getUnreadCount()
        this.unreadCount = resp.data.unreadCount
      } catch (e) {
        utils.handleHttpError(e, this)
      }
    },
    async handleNotificationDrawerClosed() {
      try {
        await NotificationService.updateLastReadAt()
        this.unreadCount = 0
      } catch (e) {
        utils.handleHttpError(e, this)
      }
    },
  },
  async mounted() {
    if (!utils.isSignedIn() || utils.isAdmin()) {
      return
    }

    this.getUnreadCount()
    webSocketService.connect()
    this.emitter.on('webSocketMessage', (data) => {
      this.unreadCount += 1

      if (!window.FlutterWebviewChannel) {
        this.$notify({
          title: data.category,
          dangerouslyUseHTMLString: true,
          message: data.message,
          duration: 6000,
        })
      }
    })
  },
  beforeUnmount() {
    webSocketService.disconnect()
  },
}
</script>

<style>
.navbar {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  padding: 15px;
  background: white;
  z-index: 100;
  width: calc(100% - 30px);
  border-bottom: 1px solid var(--el-border-color-lighter);
}

.brand {
  display: flex;
  cursor: pointer;
  margin-right: 20px;
}

.brand-icon {
  width: 32px;
}

.menu {
  display: flex;
}

.menu-item {
  padding: 10px 15px;
  cursor: pointer;
  font-size: var(--el-font-size-base);
  color: var(--el-text-color-regular);
  font-weight: 500;
}

.menu-item:hover {
  background-color: var(--el-color-primary-light-9);
  color: var(--el-color-primary);
}

.spacer {
  flex-grow: 1;
}

.el-dropdown-link {
  cursor: pointer;
  display: flex;
}

.navbar .el-dropdown {
  font-weight: 500;
}

.profile-menu-popper .el-dropdown-menu__item {
  padding: 12px 100px 12px 20px !important;
  font-weight: 500;
}

.profile-menu-popper .el-popper__arrow {
  display: none;
}

.collapse-menu {
  display: none;
  cursor: pointer;
  padding: 0 10px;
}

.notification-item {
  display: flex;
  padding: 20px;
  border-bottom: 1px solid var(--el-border-color-lighter);
  font-size: var(--el-font-size-base);
  display: flex;
  flex-direction: column;
}

.notification-message {
  font-weight: 500;
}

.notification-time {
  color: var(--el-text-color-regular);
}

.no-notifications {
  text-align: center;
  padding: 20px;
  color: var(--el-text-color-regular);
}

.notification-drawer .el-drawer__header {
  margin-bottom: 10px;
}

.notification-drawer .el-drawer__title {
  font-weight: 600;
  color: var(--el-text-color-regular);
  font-size: var(--el-font-size-extra-large);
}

.notification-drawer .el-drawer__body {
  padding: 0;
}

.unread-notification {
  background-color: var(--el-color-primary-light-9);
}

@media (max-width: 768px) {
  .menu {
    display: none;
  }
  .profile-menu {
    display: none;
  }
  .collapse-menu {
    display: block;
  }
  .drawer-menu {
    display: flex;
    flex-direction: column;
  }
  .drawer-menu-item {
    padding: 10px 0 10px 10px;
    cursor: pointer;
    font-size: var(--el-font-size-base);
    color: var(--el-text-color-regular);
    font-weight: 500;
  }
  .drawer-menu-item:hover {
    background-color: var(--el-color-primary-light-9);
    color: var(--el-color-primary);
  }
}
</style>
