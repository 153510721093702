import { createWebHistory, createRouter } from 'vue-router'
import Login from '@/components/Login.vue'
import { isSignedIn, isAdmin } from '@/helper/utils'
import { PUBLIC_PAGES, ADMIN_PAGES } from '@/helper/constants'
import { ElMessage } from 'element-plus'

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/helper-sign-up',
    name: 'HelperSignUp',
    component: () => import('@/components/HelperSignUp'),
  },
  {
    path: '/helper-sign-up2',
    name: 'DummyHelperSignUp',
    component: () => import('@/components/DummyHelperSignUp'),
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('@/components/ForgotPassword'),
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('@/components/ResetPassword'),
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('@/components/Profile.vue'),
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('@/components/Settings.vue'),
  },
  // Helper
  {
    path: '/dashboard',
    name: 'StudentDashboard',
    component: () => import('@/components/StudentDashboard'),
  },
  {
    path: '/students-old',
    name: 'StudentList',
    component: () => import('@/components/StudentList'),
  },
  {
    path: '/dropped-students',
    name: 'DroppedStudentList',
    component: () => import('@/components/DroppedStudentList'),
  },
  {
    path: '/student-search',
    name: 'StudentSearch',
    component: () => import('@/components/StudentSearch'),
  },
  {
    path: '/student-statistics',
    name: 'StudentStatistics',
    component: () => import('@/components/StudentStatistics'),
  },
  {
    path: '/student-add',
    name: 'StudentAdd',
    component: () => import('@/components/StudentAdd'),
  },
  // Admin
  {
    path: '/send-helper-sign-up-email',
    name: 'SendHelperSignUpEmail',
    component: () => import('@/components/SendHelperSignUpEmail'),
  },
  {
    path: '/admin-statistics',
    name: 'AdminStatistics',
    component: () => import('@/components/AdminStatistics'),
  },
  // Shared
  {
    path: '/events',
    name: 'EventList',
    component: () => import('@/components/EventList'),
  },
  // Public
  {
    path: '/public/:centerId/events/:eventId',
    name: 'BookingPage',
    component: () => import('@/pages/public/BookingPage'),
  },
  {
    path: '/public/:centerId/events',
    name: 'EventListPage',
    component: () => import('@/pages/public/EventListPage'),
  },

  // { path: '/:pathMatch(.*)*', redirect: '/' }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  const authRequired = !PUBLIC_PAGES.includes(to.name)

  if (to.path === '/' && isSignedIn()) {
    next(isAdmin() ? '/admin-statistics' : '/dashboard')
    return
  }

  if (to.name === 'Login' && isSignedIn()) {
    next('/')
  } else if (authRequired && !isSignedIn()) {
    next('/login')
  } else if (ADMIN_PAGES.includes(to.name) && !isAdmin()) {
    ElMessage.error('Unauthorized access')
    next('/')
  } else {
    next()
  }
})

export default router
