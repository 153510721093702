import { createI18n } from 'vue-i18n'

// Import translation messages
import en from './locales/en.json'
import es from './locales/es.json'
import pt from './locales/pt.json'

const messages = {
  en,
  es,
  pt,
}

const i18n = createI18n({
  locale: navigator.language.split('-')[0], // Set default locale e.g. 'en'
  fallbackLocale: 'en', // Set fallback locale
  messages,
})

export default i18n
